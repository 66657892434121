
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import OwnedBuilding from "@/components/reusable/claims/house-insurance/building/OwnedBuilding.vue";

export default defineComponent({
  name: "broker-building-house-insurance-claims-details",
  components: {
    OwnedBuilding
  },
  props: ["publicId"],
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Claim Details", ["Claims", "House Insurance"]);
    });
  }
});
